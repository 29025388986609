// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

require('jquery-ui-bundle');

//FontAwesome.config.mutateApproach = 'sync'

import 'bootstrap'
import './src/application.scss'

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free';
import ahoy from 'ahoy.js';

document.addEventListener("turbolinks:load", () => {


    ahoy.trackAll();

    $(document).on("change", ".paypal-check", function(e){
        var trigger_alert = true;
        if($(".paypal-option").prop("checked") == true){
            $('.paypal-email').show();
            if(!urlParam('code')) {
                $('.no-paypal').hide();
                $('.paypal-connect').show();
            }


            $('.pay-submit').hide();
            if(trigger_alert) {
                trigger_alert = false;
            }

        } else {
            trigger_alert = true;
            $('.paypal-email').hide();
            $('.no-paypal').show();
            if(!urlParam('code')) {
                $('.paypal-connect').hide();
                $('.pay-submit').show();
            }

        }

    });

    function urlParam(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if(results) {
            return results[1] || 0;
        } else {
            return 0;
        }

    }


    /*$(document).on("submit", "#pay-form", function(e){
        e.preventDefault();
        if($("#paypal").prop("checked") == true){
            alert('PayPal checked');
        }

    });*/



    $(document).on("submit", ".search-form", function(e){
        $('#search-loader').show();
        e.preventDefault();
        var isbns = $('.isbn-input').val();
        $('input').val('');
        var btn = $('#search-books-btn');
        btn.prop('disabled', true);
        setTimeout(function(){
            btn.prop('disabled', false);
        }, 2000);
        $.ajax({
            url: "/orders/add_items",
            type: "GET",
            data: {'isbn': isbns},
            success: function(data){
                $('#search-loader').hide();
                if(data) {
                    $('#search-results').html(data);
                    var error_html = $('#book-errors').html();
                    if(error_html) {
                        $('.search-error').html(error_html);
                        $('.search-error').show();
                        $('input').val('');

                    } else {
                        $('.search-error').html('');
                        $('.search-error').hide();
                    }

                }
            },
            error: function(error){
                $('#search-loader').hide();
                //alert('fail');
                console.log(error);
            }
        });
    });

    $(document).on('change', '.book-toggle', function() {
        $('#cart-loader').show();
        var isbn = $(this).val();
        var id = $(this).data('item-id');

        var url = '';
        var checked = $(this).is(':checked');
        var remove = false;
        if(checked) {
            url = "/orders/add_item";
        } else {
            url = "/orders/remove_item";
            remove = true;
        }
        $.ajax({
            url: url,
            type: "POST",
            data: {'order[isbn]': isbn, 'order[id]': id},
            success: function(data){
                $('#cart-loader').hide();
                if(data) {
                    if(data.success) {
                        $('#book-alert').removeClass('alert-danger');
                        $('#book-alert').addClass('alert-success');
                        $('#book-alert').html(data.msg);
                        $('.cart-total .total').html('$'+data.total);
                        $('#row-'+isbn).fadeOut('slow');
                        $('#book-alert').show();
                    } else {
                        $('#book-alert').removeClass('alert-success');
                        $('#book-alert').addClass('alert-danger');
                        $('#book-alert').html(data.msg);
                        $('#book-alert').show();
                    }
                    $('#book-alert').delay(3000).fadeOut('slow');

                }
            },
            error: function(error){
                $('#cart-loader').hide();
                //alert('fail');
                console.log(error);
            }
        });

    });

    $(document).on('click', '.book-delete', function() {
        var isbn = $(this).data('item-isbn');
        var id = $(this).data('item-id');
        $.ajax({
            url: '/orders/remove_item',
            type: "POST",
            data: {'order[isbn]': isbn, 'order[id]': id},
            success: function(data){
                $('#cart-loader').hide();
                if(data) {
                    if(data.success) {
                        $('#book-alert').removeClass('alert-danger');
                        $('#book-alert').addClass('alert-success');
                        $('#book-alert').html(data.msg);
                        $('#book-alert').show();
                        $('#book-'+id).fadeOut('slow');
                        $('.payment-totals .total').html("$"+data.total)
                    } else {
                        $('#book-alert').removeClass('alert-success');
                        $('#book-alert').addClass('alert-danger');
                        $('#book-alert').html(data.msg);
                        $('#book-alert').show();
                    }
                    $('#book-alert').delay(100).fadeOut('slow');

                }
            },
            error: function(error){
                $('#cart-loader').hide();
                //alert('fail');
                console.log(error);
            }
        });
    });

    //faq-zero - help
    //faq-one - isbn
    //$('.faq-zero-link').click(function(e) {
    //    $('.faq-help').click();
    //});

    $('.sortable').sortable({
        handle: '.handle',
        update: function(e, ui) {
            $.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable('serialize')
            })
        }
    });

    var editor


    $('.component[data-component="image"]').on('click', function () {
        $('#modal').modal("show");
        $('#modal .modal-title').html('Images');
        $.ajax({
            url: "/admin/images",
            type: "GET",
            success: function (data) {
                $('#modal .modal-body').html(data);
            },
            error: function (error) {
                console.log("Error:");
                console.log(error);
            }
        });
    });

    // Image pagination. Get next page results
    var images_url = '/admin/images';
    $(document.body).on('click', '#images-pagination .page-link', function () {
        reset_modal
        images_url = $(this).attr('href');
        $.ajax({
            url: images_url,
            type: "GET",
            success: function (data) {
                $('#modal .modal-body').html(data);
            },
            error: function (error) {
                console.log("Error:");
                console.log(error);
            }
        });
        //return event.preventDefault();
    });


    $(document.body).on('click', '.image-thumb', function () {
        var id = $(this).attr('data-id');
        var url = $(this).attr('data-image');
        var editor = ace.edit('page-content');
        editor.insert('<img src="'+url+'" alt="'+id+'" />');
        $('#modal').modal("hide");
    });



    $(document.body).on('click', '#image-upload', function () {
        $('#modal .modal-title').html('Upload Image');
        $.ajax({
            url: "/admin/images/new",
            type: "GET",
            success: function (data) {
                $('#modal .modal-body').html(data);
                $('#modal').addClass('remote-image-upload-form');
            },
            error: function (error) {
                console.log("Error:");
                console.log(error);
            }
        });
    });


    $(document).on('ajax:success', '#image-upload-form', function (e, data, status, xhr) {
        $.ajax({
            url: images_url,
            type: "GET",
            success: function (data) {
                $('#modal .modal-body').html(data);
            },
            error: function (error) {
                console.log("Error:");
                console.log(error);
            }
        });
    }).on('ajax:error', function (e, xhr, status, error) {
        alert('Failed')
    });

    $(document).on('ajax:success', 'a.image-delete', function (e, xhr, status, error) {
        $.ajax({
            url: images_url,
            type: "GET",
            success: function (data) {
                $('#modal .modal-body').html(data);
            },
            error: function (error) {
                console.log("Error:");
                console.log(error);
            }
        });
    }).on('ajax:error', function (e, xhr, status, error) {
        alert('Failed')
    });








});

